.imgVoltar{
  position: relative;
  left:0;
  margin: 20px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;    
}
.imgVoltar:hover{
  left: -10px;
}

@media only screen and (max-width: 600px) {
  .imgVoltar {
      margin: 30px 0px 0px 20px;
  }
}