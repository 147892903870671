.container-DeliveryProfile-principal {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-DeliveryProfile-inferior {
  display: flex;
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
}

.cx-tipo-carga {
  width: 365px;
  height: 146px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 35px 30px;
  cursor: pointer;
  z-index: 1;
}

.container-tipo-carga span {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;
  cursor: pointer;
  color: var(--color-3);
  text-align: right;
  font-size:18px;
  align-content: flex-start;
  justify-content: space-between;  
}

.container-tipo-carga input {
  display: block;
  visibility: transparent;
  position: absolute;
}

.container-tipo-carga .cx-tipo-carga.on {
  border: 1px solid var(--color-1);
}

.container-tipo-carga {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}
.container-central-DeliveryProfile {
  width: 100%;
  min-height: 500px;
}

.container-central-DeliveryProfile h4 {
  align-self: center;
  margin: 0;
}
.container-central-DeliveryProfile h1 {
  color: #ff5e00;
  /*  font: italic normal 28px/37px Segoe TV; */
  text-align: center;
  margin-bottom: 65px;
  margin-top: 100px;
}
.container-DeliveryProfile {
  margin: 15px;
}

@media only screen and (max-width: 800px) {
  .container-tipo-carga {
    flex-direction: column;
    align-items: center;
  }

  .cx-tipo-carga {
    max-width: 350px;
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
  }

  .container-central-DeliveryProfile h1 {
    color: #ff5e00;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 45px;
  }

  .posicionamento {
    margin-top: 25px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }
  .img-transpSegurado-deliveryProfile {
    margin-bottom: 15px;
  }
}