/* Payments Structure */
.container-Payments-central {
  width: 100%;
  padding-bottom: 50px;
}

.container-main-Payments {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/* End of  OrderView Structure */

/* Table Section */
.icon-caixa-payments-table {
  display: flex;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 700px;
}

.table-container-header-Payments {
  display: flex;
  flex-direction: row;
  border: 1px solid #D1D1D1;
  border-collapse: collapse;
}

.icon-caixa-payments-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 700px;
}

.icon-caixa-payments-table h2 {
  padding-top: 12px;
  margin-left: 20px;
}

.icon-caixa-payments-table h2,
.informacao-central h2 {
  font-size: 22px;
}

.icon-caixa-payments-table table,
.icon-caixa-payments-table tbody,
.icon-caixa-payments-table td:not(:nth-last-child(-n+2)) {
  border: 1px solid #D1D1D1;
  color: #5f5f5f;
  font-size: 22px;
  line-height: 22px;
  border-collapse: collapse;
  padding: 1rem;
}


/* all cells must be formated, but not the last cell child */
.icon-caixa-payments-table tbody,
.icon-caixa-payments-table tr:hover td:not(:nth-last-child(-n+2)) {
  color: black;
  font-size: 22px;
  line-height: 22px;
}

.icon-caixa-payments-table tbody td:nth-last-child(-n+1), 
.icon-caixa-payments-table tbody td:nth-last-child(-n+2){
  border: 1px solid #D1D1D1;
  border-collapse: collapse;
}

.icon-caixa-payments-table tbody tr:hover {
  box-shadow: -10px 0px 0px 0px orangered;

}

.icon-caixa-payments-table thead,
 .icon-caixa-payments-table th {
  border-collapse: collapse;
  border: 1px solid #D1D1D1;
  color: #c0c0c0;
  height: 60px;
  background-color: #F8F8F8;
}
/* End of table section */

/* Button NFe start */
.payments-nfe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height:50px;
  margin: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px #00000029, 0px 2px 4px #00000029;
  border-radius: 5px;
}

.payments-nfe-button:hover {
  background-color: #e6e6e6;

}
/* Button NFe end */

@media only screen and (max-width: 800px) {
  .icon-caixa-payments-table {
    overflow: auto;
    min-height: 400px;
    max-height: 600px;
  }
  
}