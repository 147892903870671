/* Reports Structure */
.container-Reports-central {
    width: 100%;
    padding-bottom: 50px;
}

.Reports-title {
    font-size: 30px;
    color: #ff5e00;
    margin-bottom: 20px;
    font-weight: bold;

}

.icon-box-Reports {
    display: flex;
    margin-bottom: 40px;
    margin-top: 30px;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    height: 300px;

    /* padding: 10px 0 0 10px; */
}

/* End of  Reports Structure */
.box-reports-header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    margin-top: 50px;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    height: 200px;
    padding: 30px;
    align-items: center;
    /* justify-content: center; */
}

.box-reports-header-results {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.box-reports-header-datepicker {

    background-color: #fff;
    max-width: 100%;
    text-decoration: none;
    align-items: center;
    color: var(--color-black-2);
    width: 200px;
    height: auto;
    min-height: 90px;
    border: 1px solid #00000033;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}

.box-reports-header-datepicker input {
    height: 70px;
    font-size: 20px;
    background: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    color: var(--color-1);
    margin: -7px;
}


.box-reports-picker-legends {
    font-size: 26px;
    color: #393939;
}

.box-reports-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
 }


/* For mobile devices */

@media only screen and (max-width: 800px) {
    .container-main-Reports {
        display: flex;
        flex-direction: column;
    }

    .box-reports-select {
        width: 100%;
        min-width: 100%;
    }

    .box-reports-picker-legends {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .box-reports-header-datepicker {
        width: 100%;
        min-width: 100%;
        height: auto;
        min-height: 90px;
    }

    .box-reports-header {
        flex-direction: column;
        height: auto;
    }

    .box-reports-button-container {
       width: 100%;
       min-width: 100%;
       margin-top: 30px;
    }
}