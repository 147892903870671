.btn-follow {
  width: 284px;
  height: 69px;
}

/* Only small devices */
@media only screen and (max-width: 800px) {
  .btn-follow {
    width: 100%;
    height: 69px;
    margin-bottom: 30px;
  }
}