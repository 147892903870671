.jjYngv > .wrapper {
  z-index: 1;
}
.inputProductAdd {
  position: relative;
  width: 100vw;
  max-height: 50vh;
  height: 30vh;
  top: 0;
  left: 0;
  margin-top: 0px;
  z-index: 9999;
  background: white;
}

.loader-format {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  
}
.loader {
  position: absolute;
  z-index: 1000;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  -webkit-animation:spin 6s linear infinite;
  -moz-animation:spin 6s linear infinite;
  animation:spin 6s linear infinite;
}

.pesquisar-produtos-autocomplete input {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  min-height: 50px;
  padding-left: 15px;
  max-height: 50px;
  font-size: 22px;
  overflow: auto;
  border: 1px solid #d1d1d1;
}

.pesquisar-produtos-autocomplete input::placeholder {
  color: #c4c4c4;
  font-size: 20px;
}

.pesquisar-produtos-autocomplete-item {
  cursor: pointer;
  font-size: 22px;
  border-bottom: 1px solid #c4c4c4;
  border-left: 1px solid #c4c4c4;
  /* border-right: 1px solid #c4c4c4; */
  padding: 10px;
  width: auto;
  min-width: 1230px;
}

.pesquisar-produtos-autocomplete-item:hover {
  color: var(--color-1);
}

.pesquisar-produtos-autocomplete-container {
  position: absolute;
  background: #ffffff;
  overflow: auto;
  max-height: 290px;
  height: auto;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0.1px solid #c0c0c0;
  box-shadow: 0px 10px 10px #0000001a;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 800px) {
  .inputProductAdd {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* margin-top: 200px; */
    /* top: -180px; */
    /* left: -17px; */
    /* margin-top: 30px; */
    z-index: 9999;
    background: white;
  }

  .pesquisar-produtos-autocomplete-item  {
    width: auto;
    min-width: 360px;

  }

  .pesquisar-produtos-autocomplete-container  {
    max-height: 300px;

  }

}