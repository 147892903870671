.button-radio-container {
  width: 464px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input[type='radio'] {
  display: none;
}

.radio-container label {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.custom-radio {
  border: 1px solid #9b9b9b;
  width: 30px;
  height: 30px;
  display: grid;
  place-content: center;
  border-radius: 50%;
}

.custom-radio {
  transition: 0.5s ease;
  color: #9b9b9b;
  font-size: 22px;
}

.custom-radio span {
  content: '';
  width: 17px;
  height: 17px;
  background: #9b9b9b;
  border-radius: 50%;
}

input[type='radio']:checked + .custom-radio {
  border-color: #9b9b9b;
}

input[type='radio']:checked + .custom-radio span {
  background: orangered;
}