.btn-nfe-ajuda {
  display: flex;
  min-width: 210px;
  height: 40px;
  background: #00A236;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: var(--color-white);
  padding-left: 15px;
  cursor: pointer;
  align-items: center;
  /* justify-content: center; */
  font-size: 14px;
  /* margin-right: 40px; */
}

.btn-nfe-ajuda img{
  width: 20px;
  height: 20px;
}

.btn-nfe-ajuda:hover {
  background: #13ca3af6;
}

.btn-nfe-ajuda h4 {
  margin-left: 40px;
}

/* .button-chat-main h3 {
  margin-bottom: 6px;
} */

@media only screen and (max-width: 800px) {
  .btn-nfe-ajuda {
    width:20px;
    min-width: 120px;

    height: 50px;
  }

  .btn-nfe-ajuda h4 {
    margin-left: 5px;
  }



}