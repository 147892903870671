.statusOrderview {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-2);
    border-radius: 5px;
    padding: 5px;
    color: var(--color-white);
    padding: 5px 20px;
    box-shadow: 1px 1px 10px #0000004a;
    text-align: center;
    min-width: 235px;
    height: 69px;
    font-size: 22px;
  }

  /* Alert order statusOrderview */
/* Novo */

/* Alert order status */
/* Novo */
.statusOrderview-0{
  color: #105467;
  background-color: #d1def5;
  border-color: #bedaf1;
}
/* Confirmado */
.statusOrderview-1{
  color: #c4ffea;
  background-color: #29906b;
  border-color: #bff0de;
}
/* Em coleta */
.statusOrderview-10{
  color: #cdc9ff;
  background-color: #4242ff;
  border-color: #b8c5f8;
}
/* Em entrega */
.statusOrderview-20{
  color: #e5baff;
  background-color: #914ce0;
  border-color: #efb4fc;
}
/* Cancelado */
.statusOrderview-30{
  color: #3b3b3b;
  background-color: #e3e3e3;
  border-color: #dbd5d5;
}
/* Adiado */
.statusOrderview-40{
  color: #765901;
  background-color: #ffeec8;
  border-color: #ffe6b0;
}
/* Finalizado */
.statusOrderview-50{
  color: #000000;
  background-color: #d8d6f1;
  border-color: #d7d7d7;
}