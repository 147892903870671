.btn-ajuda {
  display: inline-flex;
  min-width: 230px;
  height: 40px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: var(--color-white);
  padding: 10px 30px 0px 10px;
  cursor: pointer;
  font-size:14px;
}
.btn-ajuda:hover {
  background: #FF5E00;
}

.btn-ajuda h4 {
  margin-left: 40px;
}

.button-chat-main h3 {
  margin-bottom: 6px;
}

@media only screen and (max-width: 800px) {
  .btn-ajuda {
    width: 100%;
  }
  .btn-ajuda h4 {
    margin-left: 85px;
  }
  
}

