.ellipsis-container {
  width: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ellipsis-container div {
  width: 15px;
  height:15px;
  border-radius: 50%;
  background-color: #5f5f5f80;
}