.btnPrint {
    width: 69px;
    height: 69px;
    background-color: #FFF;
    box-shadow: 2px #F6F6F6;
    border: 1px solid white;
    border-radius: 5px;
}

.btnPrint:hover{
    border: 1px solid #dddddd;
}

.print-button-icon {
    width: 40px;
    height: 40px;
}