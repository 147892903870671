@media print and (color) {
  * {
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
}
}
@media print {
   @page {
       sheet-size: 94mm 100vh;
       size:  A4; 
       margin:0cm;
   }
   @page rotated { size : portrait; }
   header,footer, .container-navbar, .btnPadraoPreto, .btn-follow, .container-header-OrderView-buttons, .imgVoltar, .btnPrint, .statusOrderview, .whatsapp-icon{ 
       display: none; 
   }
   .orderview-phone-icon {
    margin-right: 10px;
   }

   h1 {
    font-size: 22px;
   }

   .container-header-OrderView h1{
    width: 100vw;
    font-size: 30px;
   }

   .icon-box-products {
    height: 100%;
    /* padding-top: 30px; */
   }
   .icon-box-transport-price-label {
    font-size: 30px !important;
   }

   .icon-box-transport-price-total {
    font-size: 35px !important;
   }
   /* .order-view-phone-number {
   } */

   .icon-box-worker-info {
    max-width: 30%;
   }
   .icon-box-OrderView .icon-box-logs {
     overflow: inherit !important;
   }
   .icon-box-OrderView {
      height: 100% !important;
     overflow: inherit !important;
   }
   
   .icon-box-logs {
    height: auto !important;
    padding-top: 30px;
    overflow: inherit !important;

   }

   .order-view-phone-number {
    font-size: 18px !important;
   }

      .order-view-phone-number > span{
    font-size: 12px !important;
   }

   .icon-box-products-price {
    display: none;
   }

   .logo-img-container {
    margin-top: 75px !important;
   }
   #printContainer {
       width: 210mm;
       margin: 1.2cm;
   }
   body {
      /* experimental */
      display: flex;
      align-items: center;
       margin: 1cm;
       padding: 0cm;
       /* margin-left: 60px; */
       /* margin-right: 60px; */
       color: #000000;
       background-color: #ffffff;
       /* page-break-before: always; */
       /* break-before: always; */
       width: 210mm;
       height: 297mm;
   }
   html {
      /* direction: rtl; */
      font-family: Arial,"serif";
      background: #fff;
      color: #000;
   }
}

