/* Dashboard body Section */
.container-header-dashboard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.dashboard-order-item {
  cursor: pointer;
}

.container-principal-Dashboard {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}

.map-container-header-dashboard {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.map-container-header-dashboard h2 {
  margin-left: 20px;
}

.container-dashboard-central {
  width: 100%;
  padding-bottom: 50px;
}

.container-header-dashboard h1 {
  margin-bottom: 0;
}

.img-box-dashboard {
  margin-top: 12px;
}

.icon-caixa-dashboard {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 500px;
  padding: 10px 0 0 10px;
  overflow: auto;
}
.icon-caixa-graph-header {
  display:flex;
}

.icon-caixa-dashboard h2 {
  padding-top: 12px;
  margin-left: 20px;
}

.icon-caixa-dashboard h2,
.informacao-central h2 {
  font-size: 22px;
}

/* Table */
.img-box-dashboard-table {
  margin-top: 12px;
  margin-left: 10px;
}

.icon-caixa-dashboard-table {
  display: flex;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 500px;
}

.table-container-header-dashboard {
  display: flex;
  flex-direction: row;
  border: 1px solid #D1D1D1;
}

.icon-caixa-dashboard-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 500px;
  overflow: auto;
}

.icon-caixa-dashboard-table h2 {
  padding-top: 12px;
  margin-left: 20px;
}

.icon-caixa-dashboard-table h2,
.informacao-central h2 {
  font-size: 22px;
}

.icon-caixa-dashboard-table table,
.icon-caixa-dashboard-table tbody,
.icon-caixa-dashboard-table thead,
.icon-caixa-dashboard-table td:not(:nth-last-child(-n+1)),
.icon-caixa-dashboard-table th {
  border: 1px solid #D1D1D1;
  color: #5f5f5f;
  font-size: 22px;
  line-height: 22px;
  border-collapse: collapse;
  padding: 1rem;
}

/* all cells must be formated, but not the last cell child */
.icon-caixa-dashboard-table tbody,
.icon-caixa-dashboard-table tr:hover td:not(:nth-last-child(-n+1)) {
  color: black;
  font-size: 22px;
  line-height: 22px;
}

.icon-caixa-dashboard-table tbody td:nth-last-child(-n+1) {
  border: 1px solid #D1D1D1;
  border-collapse: collapse;
}

.icon-caixa-dashboard-table tbody tr:hover {
  box-shadow: -10px 0px 0px 0px orangered;
}

.icon-caixa-dashboard-table thead {
  border: 1px solid #D1D1D1;
  color: #D1D1D1;
  height: 60px;
  background-color: #F8F8F8;
}

.dashboard-table-timer-cell{ 
  display: flex;
  justify-content: space-between;
}

.dashboard-table-timer-cell img {
  width: 25px;
}
/* End of table style */

/* Map Section */
.map-container-dashboard {
  width: 300px;
  height: 400px;
}

.icon-caixa-dashboard-map {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 500px;
  /* padding: 10px 0 0 10px; */
}
/* End map Section */

/* Order Status Colors */
.status-dashboard  {
  background-color: var(--color-gray-2);
  /* border-radius: 5px; */
  padding: 5px;
  color: var(--color-white);
  padding: 5px 20px;
  box-shadow: 1px 1px 10px #0000004a;
  text-align: center;
  min-width: 150px;
}

/* Alert order status */
/* Novo */
.status-dashboard-0{
  color: #105467;
  background-color: #d1def5;
  border-color: #bedaf1;
}
/* Confirmado */
.status-dashboard-1{
  color: #fff;
  background-color: #FF9131;
  border-color: #bff0de;
}
/* Em coleta */
.status-dashboard-10{
  color: #fff;
  background-color: #2DC6FF;
  border-color: #b8c5f8;
}
/* Em entrega */
.status-dashboard-20{
  color: #fff;
  background-color: #628E5A ;
  border-color: #efb4fc;
}
/* Cancelado */
.status-dashboard-30{
  color: #3b3b3b;
  background-color: #e3e3e3;
  border-color: #dbd5d5;
}
/* Adiado */
.status-dashboard-40{
  color: #765901;
  background-color: #ffeec8;
  border-color: #ffe6b0;
}
/* Finalizado */
.status-dashboard-50{
  color: #fff;
  background-color: #2A5CA8;
  border-color: #d7d7d7;
}

/* End of Order Status */

/* For mobile devices */

@media only screen and (max-width: 800px) {
  .container-header-dashboard {
    flex-direction: column;
    text-align: left;
  }
  .container-header-dashboard h1 {
    margin-bottom: 20px;
    align-self: baseline;
  }
}
