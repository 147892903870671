.container-principal-trackingMap {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.itens-container-central-trackingMap {
  z-index: 1;
  margin: 35px;
  align-items: center;
}
.btn-trackingMap .accordion {
  min-width: inherit!important;
  max-width: inherit!important;
}
.btn-trackingMap .accordion p {
  margin-left: 0px;
}
.btn-voltar-trackingMap {
  margin-bottom: 30px;
  margin-left: -15px;
}
.infoTrackingMap {
  max-width: 500px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  float: right;
  margin-top: 100px;
  padding: 30px;
}

.nota-trackingMap {
  width: 73px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 7px;
  top: -5px;
}
.nota-trackingMap input {
  border: none;
  width: 30px;
  font-size: 19px;
  font-weight: bold;
  color: #505050;
}
.posicionamento-infoTracking {
  display: flex;
  justify-content: space-between;
}
.dadosTrackingMap h1 {
  color: #ff5e00;
  margin-bottom: 5px;
  font-size: 22px;
}
.dadosTrackingMap input {
  margin-bottom: 5px;
  width: 200px;
  font-size: 23px;
  border: none;
  color: #424242;
  opacity: 0.5;
}
.dadosTrackingMap h2 {
  letter-spacing: 0px;
  color: #424242;
}

.dadosTrackingMap-subtitle {
  letter-spacing: 0px;
  font-size: 17px;
  color: #424242;
  font-weight: bold;
}
.perfilTrackingMap {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.coletaTrackingMap-profile-wrapper{
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
.coletaTrackingMap-profile {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
}
.btn-trackingMap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  max-height: 300px;
}
.avaliacaoTrackingMap {
  top: -30px;
  position: relative;
  align-items: center;
}
.steps-item {
  height: auto;
}

.container-qr-code-tracking-map {
  display: flex;
  justify-content: center;
}

.container-qr-code-tracking-map > img {
  width:  110px;
  height: 110px;
}

.trackingMap-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trackingMap-actions button{
  font-size: 16px;
  display: flex;
  transition-duration: 0.2s;
  background: var(--unnamed-color-303030) 0% 0% no-repeat padding-box;
  background: #ff5e00 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  justify-content: space-between;
  align-self: center;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .container-principal-trackingMap {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .dadosTrackingMap h1 {
    font-size: 20px;
    text-align: center;
  }
  .dadosTrackingMap h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }    
  .posicionamento-infoTracking {
    flex-direction: column-reverse;
  }

  .infoTrackingMap {
    display: flex;
    margin-top: 10px;
    float: none;
    flex-direction: column;
  }
  .btn-trackingMap {
    display: flex;
    margin-top: 50px;
  }

  .btn-trackingMap p {
    font-size: 17px;
  }
  .perfilTrackingMap {
    right:0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .coletaTrackingMap {
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
  }
  .dadosTrackingMap input {
    margin-bottom: 1px;
    font-size: 15px;
  }

  .container-qr-code-tracking-map {
    margin-top: 20px;
  }
  
}