.btnPadrao, 
.btnPadraoPreto {
  display: flex;
  /* width: 284px; */
  width: 320px;
  height: 69px;
  background: var(--color-1) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-size: 25px;
  justify-content: space-between;
  align-self: center;
  padding: 16px 25px 10px 25px;
  cursor: pointer;
  border: none; 
}
.btnPadraoPreto{
  background: var(--color-black-1) 0% 0% no-repeat padding-box;
}
.btnPadrao:hover {
  background: var(--color-2) 0% 0% no-repeat padding-box;
}
.btnPadraoPreto:hover{
  background: var(--color-black-2) 0% 0% no-repeat padding-box;
}
.btnPeq{
  width: auto;
  height: auto;
  font-size: 16px;
  padding: 10px 30px;
}
/* Button icon */
.btnPadrao .imgBtn, 
.btnPadrao .imgBtnPadrao, 
.btnPadraoPreto .imgBtn,
.btnPadraoPreto .imgBtnPadrao {
  align-self: center;
  text-align: right;
  margin:0;
  position: relative;
  left:0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}
.btnPadrao:hover .imgBtn, 
.btnPadrao:hover .imgBtnPadrao, 
.btnPadraoPreto:hover .imgBtn,
.btnPadraoPreto:hover .imgBtnPadrao {
  left: 10px;  
}


@media only screen and (max-width: 800px) {
  
  .btnPadraoPreto, .btnPadrao {
    width: 360px;
  }
}