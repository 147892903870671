.container-principal-OrderContact{
  width: 100vw;
  height: 1230px;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.margin-order-contact {
  margin: 15px;
}
.vlr-entrega-orderContact {
  max-width: 464px;
  height: 101px;
  background: rgba(141, 128, 128, 0.09);
  border-radius: 5px;
  align-items: flex-start;
  padding: 30px;
  display: flex;
  align-items: center;
  align-items: baseline;
  justify-content: space-between;     
}

.vlr-entrega-orderContact h1 {
  color: var(--color-1);
  font-size: 25px;
}
.vlr-entrega-orderContact-price {
  /* width: 111px; */
  /* height: 44px; */
  color: var(--unnamed-color-303030);
  text-align: left;
  /*   font: italic normal 33px Segoe TV; */
  font-size: 29px;

  color: #303030;
  border: none;
  background-color: transparent;
  margin-left: 20px;
  
}
.vlr-orderContact {
  display: flex;
  align-items: inherit;
}
.vlr-orderContact h3 {
  margin-right: 5px;
}
.cartao-orderContact {
  max-width: 464px;
  height: 250px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cartao-orderContact div {
  max-width: 414px;
  height: 70px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}
.posicionamento-inferior-orderContact {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}
.btn-finalizar-orderContact div {
  margin-top: 10px;
  text-align: right;
}

.container-central-orderContact {
  display: flex;
  justify-content: space-between;
}
.divisão-orderContact {
  border-left: 2px solid #707070;
  opacity: 0.3;
}
.input-date-cod-orderContact {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
}
.switch-orderContact {
  display: flex;
  /* align-items: end; */
  align-items: flex-end;
  margin-top: 30px;
}
.switch-orderContact span {
  margin-left: 10px;
}
.cupon-orderContact {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 75px;
}

.cupon-orderContact input {
  width: 207px;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}
.container-central-orderContact h2 {
  margin-bottom: 15px;
  color: #ff5e00;
  font-size: 20px;
}
.form-local-coleta-orderContact {
  margin-bottom: 65px;
  /* min-width: 464px; */
}
/* .form-local-entrega-orderContact {
  min-width: 464px;
} */

.formOrderContact input {
  max-width: 464px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
  text-align: center;
}

.formOrderContact select {
  max-width: 464px;
  width: 100%;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
  text-align: center;
  margin-bottom: 13px;

}

/* on error */

.formOrderContact-error input {
  max-width: 464px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 4px solid #EDADAD;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
  text-align: center;
}
.toggle-coleta-orderContact {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #505050;
}

.container-central-orderContact h4 {
  color: #505050;
  font-size: 17px;
  margin-left: 20px;
}

.cupon-orderContact input {
  width: 170px;
  height: 70px;
  font-size: 22px;
}

.container-OrderResume-inferior-tooltip {
  width: 20px;
}

@media only screen and (max-width: 1280px) {
  .container-central-orderContact {
    flex-direction: column;
  }
  .formOrderContact {
    max-width: 464px;
  }

  .vlr-entrega-orderContact h1 {
    color: var(--color-1);
    font-size: 23px;
    flex-direction: column;
  }

  .vlr-entrega-orderContact price {
    align-items: center;
  }

  .formOrderContact input {
    display: flex;
    flex-direction: column;
    /* min-width: 100%; */
  }

  

  .posicionamento-inferior-orderContact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-finalizar-orderContact div {
    text-align: center;
  }
  .cupon-orderContact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cupon-orderContact input {
    width: 360px;
    margin-bottom: 10px;
    /* width: 300px; */

  }
  .input-date-cod-orderContact {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .container-central-orderContact h4 {
    font-size: 14px;
  }
  
  .vlr-entrega-orderContact { 
    margin-top: 20px;
  }

  .btn-finalizar-orderContact {
    margin-top: 10px;
  }

  .switch-orderContact {
    justify-content: center;
  }

  .switch-OrderResume {
    margin-left: 5px;
  }
  .switch-OrderResume img {
    margin-left: 20px;
  }

}
