.container-navbar {
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.components-nav {
  width: 100%;
  height: 80px;
  background-color: #fff;
  /* background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0% 0%
   no-repeat padding-box; */
  border-bottom: 2px solid #c9c9c9;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0px 40px 0px 40px;
  align-items: center;
}

.container-navbar-center-buttons {
  display: flex;
  justify-content: space-between;
  width: 550px;
  /* margin-left: 300px; */

}

.img-navBar {
  cursor: pointer;
  width: auto;
  height: 35px;
}

.link-logo-navbar {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 800px) {
  .container-navbar-center-buttons {
     width: 360px; 
     align-items: center;
    /* margin-left: 300px; */
  
  }
  .img-navBar {
    width: auto;
    height: 32px;
  }

  .components-nav {
    margin: 0 auto;
    padding: 0px 20px 0px 20px;
    align-items: center;
  }

  .top-logo {
    display: none;
  }
}