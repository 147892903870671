.button-checkbox-container {
  /* width: 464px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between;; */
}
input[type='checkbox'] {
  display: none;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  /* column-gap: 20px; */
}

.custom-checkbox {
  border: 1px solid #9b9b9b;
  width: 25px;
  height: 25px;
  display: grid;
  place-content: center;
  border-radius: 20%;
}

.custom-checkbox {
  transition: 0.5s ease;
  color: transparent;
  font-size: 22px;
}

.custom-checkbox span {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 30%;
}

input[type='checkbox']:checked + .custom-checkbox {
  border-color: #9b9b9b;
}

input[type='checkbox']:checked + .custom-checkbox span {
  background: orangered;
}