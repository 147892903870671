/* OrderView Structure */
.container-OrderView-central {
  width: 100%;
  padding-bottom: 50px;
}

.container-main-OrderView {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.icon-box-OrderView {
  display: flex;
  margin-bottom: 40px;
  margin-top: 30px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: 300px;

  /* padding: 10px 0 0 10px; */
}

/* End of  OrderView Structure */


/* Header Section Buttons */
.qrcode-section-OrderView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-header-OrderView {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.container-header-OrderView-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 350px;
}

/* End of header Section Buttons */

/* First Section Client, Worker and Price */

/*Client Section */
.icon-box-OrderView .icon-box-client {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  padding: 30px;
  width: 45%;
  border-radius: 5px;
  text-align: left;
}

.icon-box-OrderView .icon-box-client h1 {
  margin-top: 20px;
  margin-bottom: 0;
}

.icon-box-OrderView .icon-box-client h2 {
  margin-top: 20px;
  color: #6C6C6C;
}

/* End client section */

/* Worker Section */
.icon-box-OrderView .icon-box-worker {
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 30%;
  border-radius: 5px;

}

.icon-box-OrderView .icon-box-worker h1 {
  margin-top: 20px;
  margin-bottom: 0;
}

.icon-box-OrderView .icon-box-worker h2 {
  margin-top: 20px;
  color: #5D5D5D;
}

.icon-box-worker-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 270px;
}

.icon-box-worker-info div {
  display: flex;
}

.icon-box-worker-info h2 {
  font-size: 29px;
}

.icon-box-worker-info span {
  display: block;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 16px;
}

.icon-box-worker-info img {
  margin-top: 10px;
  width: 25px;
  height: 25px;
}

/* End worker section */

/* Price Section */
.icon-box-OrderView .icon-box-price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  background-color: #FF5E00;
  border-radius: 5px;
  text-align: center;
  font-size: 35px;
  padding: 80px;

}

.icon-box-OrderView .icon-box-price .icon-box-price-info {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-bottom: 10px;
  color: #F1D900;
  font-weight: bold;
}

.icon-box-OrderView .icon-box-price .icon-box-price-info span {
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.icon-box-OrderView .icon-box-price .icon-box-price-info div {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  font-style: italic;
  font-weight: bold;
  color: white;
}

/* End of price section */


/* Products Section */
.icon-box-OrderView .icon-box-products {
  display: flex;
  color: #6C6C6C;

  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 40px;
  overflow: auto;

}

/* .icon-box-OrderView .icon-box-products-price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  background-color: #F9F9F9;
  border-radius: 5px;
  text-align: center;
  font-size: 35px;
  padding: 80px;
  border: 1px solid #D1D1D1;

} */

.icon-box-OrderView .icon-box-products-price-info {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-bottom: 10px;
  color: #6C6C6C;
  font-weight: bold;
}

.icon-box-OrderView .icon-box-products-price .icon-box-products-price-info div {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  font-style: italic;
  font-weight: bold;
  color: #FF5E00;

}

.icon-box-OrderView .icon-box-products-price .icon-box-products-price-info span {
  font-size: 30px;
  font-style: italic;
  /* font-weight: bold; */
  margin-bottom: 20px;
}


/* End products section */

/* Logs Section */
.icon-box-OrderView .icon-box-logs {
  display: flex;
  color: #6C6C6C;

  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 40px;
  overflow: auto;
  font-weight: bolder;

}

/* End Logs section */


/* Removing margin by default on H1 element */
.container-header-OrderView h1 {
  margin: 0;
}


/* For mobile devices */

@media only screen and (max-width: 800px) {
  .icon-box-OrderView {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  /* First Section Client, Worker and Price */

  /*Client Section */

  .icon-box-OrderView .icon-box-client {
    width: 100%;
    min-height: 300px;
  }

  /* End client section */

  /* Worker Section */
  .icon-box-OrderView .icon-box-worker {
    width: 100%;
    min-height: 300px;
  }

  /* End worker section */

  /* Price Section */
  .icon-box-OrderView .icon-box-price {
    width: 100%;
    min-height: 300px;
  }

  /* End of price section */


  /* Products Section */
  .icon-box-OrderView .icon-box-products {
    flex-direction: column;
    width: 100%;
    min-height: 300px;
  }

  .icon-box-OrderView .icon-box-products-price {
    width: 100%;
    min-height: 300px;
  }

  /* End products section */

  /* Logs Section */
  .icon-box-OrderView .icon-box-logs {
    width: 100%;
    min-height: 300px;
  }

  .icon-box-logs-item:not(:first-child) {
    padding-top: 60px;
  }

  /* End Logs section */

  /* Header Section */
  .container-header-OrderView {
    flex-direction: column-reverse;

  }

  /* Header Section Buttons */
  .qrcode-section-OrderView {
    width: 100%;
  }

  .qrcode-section-OrderView img {
    width: 100%;
  }

  .qrcode-section-OrderView span {
    font-size: 22px;
    font-weight: bold;
  }

  .container-header-OrderView-buttons {
    min-width: 342px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* End of Header Section */
}