.select-component-input {
  background-color: #fff;
  display: flex;
  max-width: 100%;
  text-decoration: none;
  align-items: center;
  color: var(--color-black-2);
  width: 300px;
  height: auto;
  color: #393939;
  min-height: 90px;
  border: 1px solid #00000033;
  border-radius: 5px;
  font-size: 20px;
  align-items: center;
  padding: 30px;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.select-component-input::placeholder {
  font-size: 22px;
}